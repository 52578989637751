<template>
  <div>
    <div>
      <p>店铺头部横幅图片</p>
      <MaterialSelector
        :count="1"
        type="image"
        class="mt"
        @receiver="handleSelectionResult($event, 1)"
      >
      </MaterialSelector>
    </div>

    <p class="mt">店铺幻灯片图片</p>
    <MaterialSelector
      :count="1"
      type="image"
      class="mt"
      @receiver="handleSelectionResult($event, 2)"
    >
    </MaterialSelector>
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      @selection-change="handleSelectionChange"
      class="mt title"
    >
      <el-table-column
        type="selection"
        align="center"
        width="55"
      ></el-table-column>
      <el-table-column
        align="center"
        :label="this.$t('offer.portrait')"
        width="130"
      >
        <template v-slot="scope">
          <img :src="scope.row.img" width="40" height="40" class="head_pic" />
        </template>
      </el-table-column>
      <el-table-column
        prop="logisticName"
        align="center"
        :label="this.$t('offer.name')"
      >
      </el-table-column>
      <el-table-column
        prop="country"
        align="center"
        :label="this.$t('offer.country')"
      >
      </el-table-column>
      <el-table-column prop="price" align="center" label="CBM">
      </el-table-column>
      <el-table-column
        prop="freightMode"
        align="center"
        :label="this.$t('offer.freightMode')"
      >
      </el-table-column>
      <el-table-column
        prop="email"
        align="center"
        :label="this.$t('offer.mailbox')"
      >
      </el-table-column>
      <el-table-column
        prop="contack"
        align="center"
        :label="this.$t('offer.contact')"
      >
      </el-table-column>
      <el-table-column
        prop="addr"
        align="center"
        :label="this.$t('offer.address')"
      >
      </el-table-column>
      <el-table-column align="center" :label="this.$t('offer.operation')">
        <template v-slot="scope">
          <el-button @click="modify(scope.$index)" class="modify">{{
            $t("offer.modify")
          }}</el-button> </template
        >>
      </el-table-column>
    </el-table>

    <p class="mt">店铺关于我们图片</p>
    <MaterialSelector
      :count="1"
      type="image"
      class="mt"
      @receiver="handleSelectionResult($event, 2)"
    >
    </MaterialSelector>
    <div></div>
  </div>
</template>

<script>
import MaterialSelector from "@/components/MaterialSelector";
export default {
  components: {
    MaterialSelector,
  },
  data() {
    return {};
  },
  methods: {
    // 图片选择器
    handleSelectionResult(result, type) {
      console.log("result, type:", result, type);
      if (type === 1) {
        // 主图
        this.storeData_banner = result.filename;
      }
      if (type === 2) {
        this.swiper_image = result.filename;
      }
      if (type === 3) {
        this.storeData_aboutImage = result.filename;
      }
    },
  },
};
</script>

<style scoped>
</style>